import logo from '../images/Topher_Hook_Logo.png'

const Header = () => {
    return (
        <header >
            <a href='/'><img src={logo} alt='Topher Hook Logo' /></a>
        </header>
    )
}

export default Header
