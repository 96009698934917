
const Contact = () => {
    return (
        <div>
            <h1 className="pageTitle">Contact</h1>
            <p>email me at topherhook@gmail.com</p>
        </div>
    )
}

export default Contact
