
const Welcome = () => {
    return (
        <div>
            <h1 className="pageTitle">Welcome</h1>
            <p>Hello. My name is Topher Hook, and I built this page using React.</p>
        </div>
    )
}

export default Welcome
